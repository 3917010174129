import { DonateFormItem, LabelFlex } from './DonateCommon';
import { SupportStep1Data } from './DonateStep1';
import { SupportStep2Data } from './DonateStep2';
import { FormButton } from '../auth/AuthCommon';
import { SupportModuleControllerService } from '@/__generated__/CommonApi';
import { RegularPayInfoVo } from '@/__generated__/FrontApi';
import IconArs from '@/assets/img/ico_ars@2x.png';
import Agreement from '@/components/Agreement';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import FormTextTip from '@/components/Form/FormTextTip';
import LabelText from '@/components/Form/LabelText';
import Signature from '@/components/Form/Signature';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import HR from '@/components/HR';
import { InputCheckbox } from '@/components/Input/InputCheckbox';
import InputGroupCard, {
  InputGroupCardDash,
  InputGroupCardSlash,
} from '@/components/Input/InputGroupCard';
import InputNumber from '@/components/Input/InputNumber';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import Select from '@/components/Select';
import {
  IconNotiSub,
  IconNotiText,
  IconNotiTitle,
} from '@/components/Text/TextIconNoti';
import {
  NotiBox,
  NotiItem,
  NotiList,
  NotiParagraph,
  NotiTitle,
} from '@/components/Text/TextNoti';
import InicisAccountCheck from '@/components/ThirdParty/InicisAccountCheck';
import { H2, H4 } from '@/components/Titles';
import {
  SupportAgreeContent,
  SupportPrivacyAgreeContent,
} from '@/data/agreements';
import {
  PAYMENT_TYPE_NAMES,
  PAYTYPE_ONCE_BANKING,
  PAYTYPE_ONCE_CARD,
  PAYTYPE_REGULAR_AUTO_BANKING,
  PAYTYPE_REGULAR_CARD,
  PAYTYPE_REGULAR_NAVERPAY,
  REGCD_COMPLETE,
  REGCD_MODIFY,
  REGCD_NEW,
  PAYTYPE_ONCE_NAVERPAY,
} from '@/helpers/PaymentHelper';
import {
  ENUM_SUPPORTER_TYPE,
  MEMBER_TYPES,
  SUPPORT_TERM_ONCE,
  SUPPORT_TERM_REGULAR,
} from '@/helpers/SupportHelper';
import { mobileRegex } from '@/helpers/ValidationHelper';
import { SupportModuleInputData } from '@/pages/donate';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import dayjs from 'dayjs';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

const FormContainer = styled.div`
  ${IconNotiText} {
    margin-bottom: 32px;

    ${IconNotiTitle} {
      font-weight: 400;
    }
  }
`;

const DirectDebit = styled.div`
  dl {
    margin-bottom: 19px;

    &:last-child {
      margin-bottom: 0;
    }
    dt {
      margin-bottom: 13px;
    }
  }
`;

const AgreeHead = styled.div`
  line-height: 1.2;
  padding: 29px 0;
  color: #b7b8ba;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  margin-top: 46px;
  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #b7b8ba;
  padding-bottom: 16px;
`;

const FormTip = styled.div<{
  type?: string;
}>`
  padding: 22px 20px;
  ${({ type }) => {
    if (type === `blue`) {
      return `
        background-color: #e2f0f6;
        color: #1cabe2;
        `;
    }
    if (type === `gray`) {
      return `
        background-color: #f8f9fa;
        color: #2d2926;
        `;
    }
    return ``;
  }}
`;

export interface SupportStep3Data {
  paymentType: keyof typeof PAYMENT_TYPE_NAMES;
  // 계좌관련 정보
  bankingOwnerType: 'self' | 'others';
  bankingOwnerName: string;
  bankingOwnerRelation: string;
  bankingOwnerBirthday: string;
  bankingOwnerPhone: string;

  paymentBankingAgreeType: 'ARS' | 'sign';
  paymentDay: string;
  paymentBankCode: string;
  bankingNumber: string;
  // 전자서명 바이너리
  signBinary: string;
  // 신용카드 정보
  cardNumber1: string;
  cardNumber2: string;
  cardNumber3: string;
  cardNumber4: string;
  cardExpireM: string;
  cardExpireY: string;
  cardOwnerBirthday: string;
  // 이용약관
  policyAgree: boolean;
}
interface DonateStep3Props {
  onSubmit: (data: SupportStep3Data) => void;
  queryParams: any;
  totalData: Partial<SupportStep1Data & SupportStep2Data>;
  hideInitialHr?: boolean;
  currentRegularPayInfo?: RegularPayInfoVo;
  moduleData?: SupportModuleInputData;
}
const DonateStep3: FC<DonateStep3Props> = observer(
  ({
    totalData: {
      memberTypeCode,
      supporterType,
      supportTerm,
      memberName,
      birthday,
      mobilePhoneNumber,
      supportAmount,
      supportAmountInput,
      businessNumber,
    },
    hideInitialHr,
    onSubmit,
    currentRegularPayInfo,
    moduleData,
  }) => {
    const popupStore = usePopupStore();
    const userStore = useUserStore();
    const [isValidBankAccount, setIsValidBankAccount] = useState(false);
    const [isCardBirthdaySame, setIsCardBirthdaySame] = useState(false);
    const [regularPayInfo, setRegularPayInfo] = useState<
      RegularPayInfoVo | undefined
    >(currentRegularPayInfo);

    const {
      register,
      watch,
      setValue,
      control,
      formState: { errors, isSubmitting },
      handleSubmit,
      getValues,
      reset,
      trigger,
    } = useForm<SupportStep3Data>({
      defaultValues: {
        signBinary: ``,
        cardNumber1: ``,
        cardNumber2: ``,
        cardNumber3: ``,
        cardNumber4: ``,
        cardExpireM: ``,
        cardExpireY: ``,
        bankingOwnerType: `self`,
        paymentType:
          supportTerm === SUPPORT_TERM_ONCE
            ? PAYTYPE_ONCE_CARD
            : PAYTYPE_REGULAR_AUTO_BANKING,
        paymentBankingAgreeType: `sign`,
      },
    });
    const {
      allBank: { nodes: banks },
    } = useStaticQuery(graphql`
      query {
        allBank {
          nodes {
            code
            description
          }
        }
      }
    `);

    const bankingOwnerType = watch(`bankingOwnerType`);
    const paymentType = watch(`paymentType`);
    const paymentBankingAgreeType = watch(`paymentBankingAgreeType`);

    // 총 후원금액
    const finalAmount = useMemo(
      () => Number(supportAmount || supportAmountInput || 0),
      [supportAmount, supportAmountInput],
    );

    const cardNumberError =
      errors.cardNumber1 ||
      errors.cardNumber2 ||
      errors.cardNumber3 ||
      errors.cardNumber4;

    const cardExpireError = errors.cardExpireM || errors.cardExpireY;

    // 생일에서 나이 계산
    const age = useMemo(
      () => birthday && dayjs().diff(birthday, `year`, true),
      [birthday],
    );

    // 14세 미만 여부
    const isUnderFourteen = age !== null && age < 14 && age >= 0;

    // 정기후원중인 회원인지 여부 (readonly 처리용)
    const isRegularDonor = useMemo(() => !!regularPayInfo, [regularPayInfo]);

    // 전자서명 가능여부
    // 개인 & 본인이고, 14세 이상일때만 서명 가능
    const canSign = useMemo(
      () =>
        supporterType === ENUM_SUPPORTER_TYPE.person &&
        bankingOwnerType === `self` &&
        !isUnderFourteen,
      [bankingOwnerType, isUnderFourteen, supporterType],
    );

    // 자동이체 & 개인/단체 선택에 따라 기본값 세팅
    useEffect(() => {
      // 정기후원정보 불러온게 없을때 적용
      if (regularPayInfo) {
        return;
      }
      if (paymentType === PAYTYPE_REGULAR_AUTO_BANKING) {
        if (bankingOwnerType === `self`) {
          setValue(`bankingOwnerName`, memberName);
          setValue(
            `bankingOwnerBirthday`,
            supporterType === ENUM_SUPPORTER_TYPE.person
              ? birthday?.substring(2,8)
              : businessNumber,
          );
          // 계좌인증상태 초기화
          setIsValidBankAccount(false);
        }
        if (bankingOwnerType === `others`) {
          setValue(`bankingOwnerName`, ``);
          setValue(`bankingOwnerBirthday`, ``);
          // 계좌인증상태 초기화
          setIsValidBankAccount(false);
        }
        // 전자서명 가능여부에 따라 세팅
        setValue(`paymentBankingAgreeType`, canSign ? `sign` : `ARS`);
      }
    }, [
      bankingOwnerType,
      birthday,
      businessNumber,
      canSign,
      memberName,
      paymentType,
      regularPayInfo,
      setValue,
      supporterType,
    ]);

    // 정기후원 정보 로딩
    const loadRegularInfo = useCallback(async () => {
      // 로그인 되어있다면
      if (userStore.user) {
        const {
          data: _data,
          resultCode,
        } = await SupportModuleControllerService.regularPayInfoUsingGet({
          donorId: userStore.user.donorId,
        });

        const data = _data as RegularPayInfoVo;

        if (resultCode === `success`) {
          setRegularPayInfo(data);
        }
      }
    }, [userStore.user]);

    useEffect(() => {
      // 정기후원자라면
      if (userStore.isLoggedIn() && userStore.isRegularDonor()) {
        // 정기후원정보 로딩
        loadRegularInfo();
      }
    }, [loadRegularInfo, userStore]);

    // 정기후원정보 있다면 세팅
    useEffect(() => {
      if (!regularPayInfo || supportTerm !== SUPPORT_TERM_REGULAR) {
        return;
      }
      if (regularPayInfo.paymentType === PAYTYPE_REGULAR_AUTO_BANKING) {
        // 본인타인 확인 먼저 적용
        reset({
          paymentType: regularPayInfo.paymentType,
          bankingOwnerType:
            regularPayInfo.accountType === `본인` ? `self` : `others`,
          bankingOwnerName: regularPayInfo.accountOwnerName,
          bankingOwnerBirthday: regularPayInfo.accountJuminNumber,
          bankingOwnerRelation: regularPayInfo.relation,
          paymentBankCode: regularPayInfo.bankCode,
          bankingNumber: regularPayInfo.accountNumber,
          bankingOwnerPhone: regularPayInfo.accountOwnerPhone,
          paymentDay: regularPayInfo.paymentDay,
          paymentBankingAgreeType:
            supporterType === ENUM_SUPPORTER_TYPE.person &&
            regularPayInfo.accountType === `본인`
              ? regularPayInfo.acsType
              : `ARS`,
        });
        // 신규,변경,등록완료 상태의 계좌는 인증된것으로 간주
        if (
          [REGCD_NEW, REGCD_MODIFY, REGCD_COMPLETE].includes(
            regularPayInfo.regCd,
          )
        ) {
          setIsValidBankAccount(true);
        }
      } else if (regularPayInfo.paymentType === PAYTYPE_REGULAR_CARD) {
        reset({
          paymentType: regularPayInfo.paymentType,
          cardNumber1: regularPayInfo.cardNumber?.slice(0, 4),
          cardNumber2: regularPayInfo.cardNumber?.slice(4, 8),
          cardNumber3: regularPayInfo.cardNumber?.slice(8, 12),
          cardNumber4: regularPayInfo.cardNumber?.slice(
            12,
            regularPayInfo.cardNumber.length,
          ),
          cardExpireM: regularPayInfo.validMonth,
          cardExpireY: regularPayInfo.validYear,
          cardOwnerBirthday: regularPayInfo.cardJuminNumber,
          paymentDay: regularPayInfo.paymentDay,
        });
        setIsCardBirthdaySame(birthday === regularPayInfo.birthDay);
      } else if (regularPayInfo.paymentType === PAYTYPE_REGULAR_NAVERPAY) {
        reset({
          paymentType: regularPayInfo.paymentType,
          paymentDay: regularPayInfo.paymentDay,
        });
      }
    }, [birthday, regularPayInfo, reset, setValue, supportTerm, supporterType]);

    return (
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!hideInitialHr && <HR color="#1cabe2" />}
          {supportTerm === SUPPORT_TERM_REGULAR && (
            <IconNotiText
              css={`
                margin-top: 24px;
              `}
            >
              <IconNotiTitle>
                기존 등록하신 결제정보로만 후원이 가능합니다.
              </IconNotiTitle>
              <IconNotiSub>
                결제정보 변경은{` `}
                <strong>마이페이지 {`>`} 후원 및 결제정보</strong>
                에서 진행해 주세요.
              </IconNotiSub>
            </IconNotiText>
          )}
          {supportTerm === SUPPORT_TERM_ONCE && (
            <DonateFormItem noBorder>
              <FormGroup>
                <H4>결제수단</H4>
                <InputRadioGroup full>
                  <InputRadio
                    name="paymentType"
                    ref={register}
                    tab
                    value={PAYTYPE_ONCE_CARD}
                    label="신용카드"
                    defaultChecked
                  />
                  {/* <InputRadio
                    name="paymentType"
                    ref={register}
                    tab
                    value={PAYTYPE_ONCE_PHONE}
                    label="휴대폰"
                  /> */}
                  <InputRadio
                    name="paymentType"
                    ref={register}
                    tab
                    value={PAYTYPE_ONCE_BANKING}
                    label="무통장입금"
                  />
                  <InputRadio
                    name="paymentType"
                    ref={register}
                    tab
                    value={PAYTYPE_ONCE_NAVERPAY}
                    label="네이버페이"
                  />
                </InputRadioGroup>
              </FormGroup>
            </DonateFormItem>
          )}
          {/* 정기후원시 */}
          {supportTerm === SUPPORT_TERM_REGULAR && (
            <>
              <DonateFormItem>
                <FormGroup noMargin>
                  <H4>결제수단</H4>
                  <InputRadioGroup full>
                    <InputRadio
                      name="paymentType"
                      ref={register}
                      tab
                      value={PAYTYPE_REGULAR_AUTO_BANKING}
                      label="자동이체"
                      readOnly={isRegularDonor}
                    />
                    <InputRadio
                      name="paymentType"
                      ref={register}
                      tab
                      value={PAYTYPE_REGULAR_CARD}
                      label="신용카드"
                      readOnly={isRegularDonor}
                    />
                    <InputRadio
                      name="paymentType"
                      ref={register}
                      tab
                      value={PAYTYPE_REGULAR_NAVERPAY}
                      label="네이버페이"
                      readOnly={isRegularDonor}
                    />
                  </InputRadioGroup>
                </FormGroup>
              </DonateFormItem>
              {paymentType === PAYTYPE_REGULAR_AUTO_BANKING && (
                <>
                  <DonateFormItem>
                    <FormGroup>
                      <H4>자동이체 계좌 정보</H4>
                      {/* 개인 선택의 경우 */}
                      {supporterType === ENUM_SUPPORTER_TYPE.person && (
                        <InputRadioGroup full>
                          <InputRadio
                            name="bankingOwnerType"
                            ref={register}
                            tab
                            value="self"
                            label="본인"
                            readOnly={isRegularDonor}
                          />
                          <InputRadio
                            name="bankingOwnerType"
                            ref={register}
                            tab
                            value="others"
                            label="타인"
                            readOnly={isRegularDonor}
                          />
                        </InputRadioGroup>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <LabelText require>예금주명</LabelText>
                      <InputText
                        name="bankingOwnerName"
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        placeholder="예금주명 입력"
                        readOnly={
                          isRegularDonor ||
                          (supporterType === ENUM_SUPPORTER_TYPE.person &&
                            bankingOwnerType === `self`) ||
                          isValidBankAccount
                        }
                      />
                      {errors.bankingOwnerName && (
                        <ErrorMessage>
                          {errors.bankingOwnerName.message}
                        </ErrorMessage>
                      )}
                    </FormGroup>
                    {/* 개인-타인 */}
                    {supporterType === ENUM_SUPPORTER_TYPE.person &&
                      bankingOwnerType === `others` && (
                        <FormGroup>
                          <LabelText require={!isRegularDonor}>
                            예금주와의 관계
                          </LabelText>
                          <Select
                            name="bankingOwnerRelation"
                            ref={register({
                              required: {
                                value: !isRegularDonor,
                                message: `필수 입력입니다`,
                              },
                            })}
                            readOnly={isRegularDonor}
                          >
                            <option value="">관계 선택</option>
                            <option>부모</option>
                            <option>친척</option>
                            <option>지인</option>
                            <option>선생님</option>
                            <option>기타</option>
                          </Select>
                          {errors.bankingOwnerRelation && (
                            <ErrorMessage>
                              {errors.bankingOwnerRelation.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                      )}
                    {/* 법인 */}
                    {Object.keys(
                      MEMBER_TYPES[ENUM_SUPPORTER_TYPE.corporation],
                    ).includes(memberTypeCode || ``) ? (
                      <FormGroup>
                        <LabelText require>예금주 사업자번호</LabelText>
                        <Controller
                          control={control}
                          name="bankingOwnerBirthday"
                          rules={{
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          }}
                          as={
                            <InputNumber
                              format="##########"
                              placeholder="예금주 사업자번호 입력(&lsquo;-&rsquo; 제외)"
                              readOnly={isRegularDonor}
                            />
                          }
                        />
                        {errors.bankingOwnerBirthday && (
                          <ErrorMessage>
                            {errors.bankingOwnerBirthday.message}
                          </ErrorMessage>
                        )}
                      </FormGroup>
                    ) : (
                      // 개인 또는 단체
                      <FormGroup>
                        <LabelText require>예금주 생년월일</LabelText>
                        <Controller
                          control={control}
                          name="bankingOwnerBirthday"
                          rules={{
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                            validate: (value) =>
                              dayjs(value, `YYMMDD`, true).isValid() ||
                              `올바르지 않은 형식입니다`,
                          }}
                          as={
                            <InputNumber
                              format="######"
                              placeholder="예금주 생년월일 6자리 입력(YYMMDD)"
                              readOnly={
                                isRegularDonor ||
                                (supporterType === ENUM_SUPPORTER_TYPE.person &&
                                  bankingOwnerType === `self`) ||
                                isValidBankAccount
                              }
                            />
                          }
                        />
                        {errors.bankingOwnerBirthday && (
                          <ErrorMessage>
                            {errors.bankingOwnerBirthday.message}
                          </ErrorMessage>
                        )}
                      </FormGroup>
                    )}
                    {/* 개인-타인 */}
                    {supporterType === ENUM_SUPPORTER_TYPE.person &&
                      bankingOwnerType === `others` && (
                        <FormGroup>
                          <LabelText require={!isRegularDonor}>
                            예금주 휴대폰번호
                          </LabelText>
                          <Controller
                            control={control}
                            name="bankingOwnerPhone"
                            rules={{
                              required: {
                                value: !isRegularDonor,
                                message: `필수 입력입니다`,
                              },
                              pattern: {
                                value: mobileRegex,
                                message: `올바른 휴대폰번호가 아닙니다`,
                              },
                              validate: {
                                isValidRange: (value) => {
                                  if (isRegularDonor) return true;
                                  return (
                                    (value.substr(0, 3) === `010` &&
                                      value.length === 11) ||
                                    (value.substr(0, 3) !== `010` &&
                                      value.length === 10) ||
                                    `휴대폰번호 자릿수를 확인해주세요`
                                  );
                                },
                              },
                            }}
                            as={
                              <InputNumber
                                format="###########"
                                placeholder="예금주 휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                                readOnly={isRegularDonor}
                              />
                            }
                          />
                          {errors.bankingOwnerPhone && (
                            <ErrorMessage>
                              {errors.bankingOwnerPhone.message}
                            </ErrorMessage>
                          )}
                        </FormGroup>
                      )}
                    <FormGroup>
                      <LabelText require>계좌번호</LabelText>
                      <Select
                        name="paymentBankCode"
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        })}
                        readOnly={isRegularDonor || isValidBankAccount}
                      >
                        <option value="">은행 선택</option>
                        {banks.map((bank) => (
                          <option key={bank.code} value={bank.code}>
                            {bank.description}
                          </option>
                        ))}
                      </Select>
                      {errors.paymentBankCode && (
                        <ErrorMessage>
                          {errors.paymentBankCode.message}
                        </ErrorMessage>
                      )}
                      <Row
                        className="certify"
                        css={`
                          margin-top: 8px;
                        `}
                      >
                        <div className="col-right">
                          <Controller
                            control={control}
                            name="bankingNumber"
                            rules={{
                              required: {
                                value: true,
                                message: `필수 입력입니다`,
                              },
                              validate: () =>
                                isValidBankAccount || `계좌인증은 필수입니다`,
                            }}
                            as={
                              <InputNumber
                                placeholder="계좌번호 입력(&lsquo;-&rsquo; 제외)"
                                readOnly={isRegularDonor || isValidBankAccount}
                                allowNegative={false}
                                allowLeadingZeros
                              />
                            }
                          />
                        </div>
                        <div className="col-left">
                          {isValidBankAccount ? (
                            <FormButton color="grey" disabled>
                              인증완료
                            </FormButton>
                          ) : (
                            <InicisAccountCheck
                              getParams={() => ({
                                name: getValues(`bankingOwnerName`),
                                account: getValues(`bankingNumber`),
                                bankCode: getValues(`paymentBankCode`),
                              })}
                              renderSubmitButton={(buttonProps) => (
                                <FormButton outline {...buttonProps}>
                                  계좌인증
                                </FormButton>
                              )}
                              onSuccess={() => {
                                setIsValidBankAccount(true);
                                trigger(`bankingNumber`);
                              }}
                              onError={(message) => popupStore.show(message)}
                            />
                          )}
                        </div>
                      </Row>
                      {errors.bankingNumber && (
                        <ErrorMessage>
                          {errors.bankingNumber.message}
                        </ErrorMessage>
                      )}
                      <Row
                        className="certify"
                        css={`
                          margin-top: 8px;
                          align-items: center;
                        `}
                      >
                        <div className="col-right">
                          <FormTextTip>
                            <strong>
                              가상 계좌, 미성년자 명의 계좌, 기업은행 휴대폰
                              계좌는 사용하실 수 없습니다.
                            </strong>
                          </FormTextTip>
                        </div>
                      </Row>
                      {isRegularDonor && !isValidBankAccount && (
                        <ErrorMessage>
                          * 결제정보 확인이 필요합니다.{` `}
                          <Link to="/mypage/support-info">
                            <u>마이페이지</u>
                          </Link>
                          에서 결제정보를 수정해 주세요.
                        </ErrorMessage>
                      )}
                    </FormGroup>
                  </DonateFormItem>
                  <DonateFormItem noBorder>
                    <FormGroup noMargin>
                      <H4>결제일</H4>
                      <InputRadioGroup full>
                        <InputRadio
                          name="paymentDay"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          value="05"
                          label="05일"
                          readOnly={isRegularDonor}
                        />
                        <InputRadio
                          name="paymentDay"
                          ref={register}
                          value="15"
                          label="15일"
                          readOnly={isRegularDonor}
                        />
                        <InputRadio
                          name="paymentDay"
                          ref={register}
                          value="25"
                          label="25일"
                          readOnly={isRegularDonor}
                        />
                        <InputRadio
                          name="paymentDay"
                          ref={register}
                          value="30"
                          label="말일"
                          readOnly={isRegularDonor}
                        />
                      </InputRadioGroup>
                      {errors.paymentDay && (
                        <ErrorMessage>{errors.paymentDay.message}</ErrorMessage>
                      )}
                    </FormGroup>
                  </DonateFormItem>
                  {/* 자동이체 정보가 없다면 동의 받기 */}
                  {!regularPayInfo && (
                    <DonateFormItem noBorder>
                      <FormGroup>
                        <H4>자동이체 출금 동의</H4>
                        <DirectDebit>
                          {canSign && (
                            <dl>
                              <dt>
                                <InputRadio
                                  name="paymentBankingAgreeType"
                                  ref={register({
                                    required: {
                                      value: true,
                                      message: `필수 입력입니다`,
                                    },
                                  })}
                                  value="sign"
                                  label="전자서명"
                                />
                              </dt>
                              {paymentBankingAgreeType === `sign` && (
                                <dd>
                                  <Controller
                                    control={control}
                                    name="signBinary"
                                    rules={{
                                      required: {
                                        value: true,
                                        message: `필수 입력입니다`,
                                      },
                                    }}
                                    as={<Signature />}
                                  />
                                  {errors.signBinary && (
                                    <ErrorMessage>
                                      {errors.signBinary.message}
                                    </ErrorMessage>
                                  )}
                                </dd>
                              )}
                            </dl>
                          )}
                          <dl>
                            <dt>
                              {bankingOwnerType === `others` ||
                              isUnderFourteen ? (
                                <InputRadio
                                  name="paymentBankingAgreeType"
                                  ref={register({
                                    required: {
                                      value: true,
                                      message: `필수 입력입니다`,
                                    },
                                  })}
                                  value="ARS"
                                  label="ARS"
                                />
                              ) : (
                                <InputRadio
                                  name="paymentBankingAgreeType"
                                  ref={register({
                                    required: {
                                      value: true,
                                      message: `필수 입력입니다`,
                                    },
                                  })}
                                  value="ARS"
                                  label="ARS"
                                />
                              )}
                            </dt>
                            {paymentBankingAgreeType === `ARS` && (
                              <dd>
                                <NotiBox>
                                  <NotiParagraph>
                                    금융결제원의 방침에 따라 개인정보 보호를
                                    위해 잠시 후 ARS전화로 자동이체 출금동의를
                                    진행합니다.
                                  </NotiParagraph>
                                  <NotiParagraph>
                                    수신거부 또는 입력하신 휴대폰번호가 다를
                                    경우 출금동의여부 확인이 어려우니 아래
                                    번호의 전화를 꼭 수신해 주시기 바랍니다.
                                  </NotiParagraph>
                                  <a
                                    href="tel:027238215"
                                    css={`
                                      font-size: 24px;
                                      font-weight: bold;
                                      line-height: 1.58;
                                      letter-spacing: -1.2px;
                                      margin-top: 24px;
                                      display: block;
                                      color: #1cabe2;
                                    `}
                                  >
                                    <img
                                      src={IconArs}
                                      alt="ARS 아이콘"
                                      css={`
                                        display: inline-block;
                                        width: 40px;
                                        margin-right: 16px;
                                        vertical-align: top;
                                      `}
                                    />
                                    02 - 723 - 8215
                                  </a>
                                </NotiBox>
                              </dd>
                            )}
                          </dl>
                        </DirectDebit>
                        {errors.paymentBankingAgreeType && (
                          <ErrorMessage>
                            {errors.paymentBankingAgreeType.message}
                          </ErrorMessage>
                        )}
                      </FormGroup>
                      <NotiBox>
                        <NotiTitle>자동이체 후원 시 유의사항</NotiTitle>
                        <NotiParagraph>
                          <NotiItem>
                            자동이체의 경우, 금융결제원을 통해 은행에 접수하는
                            과정을 거쳐야 합니다. 신청하신 약정일로부터
                            영업일(평일) 기준 5일 전에 신청하신 경우에만 해당
                            월에 후원이 가능합니다.
                          </NotiItem>
                          <NotiItem>
                            매월 선택하신 날짜(평일 기준)에 약정하신 금액이 자동
                            출금됩니다. 그러나 해당 일에 후원금이 출금되지 않은
                            경우, 1회에 한해 다음 결제일(5일→15일, 15일→25일,
                            25일→말일, 말일→익월 5일)에 재출금이 진행됩니다.
                          </NotiItem>
                        </NotiParagraph>
                      </NotiBox>
                    </DonateFormItem>
                  )}
                </>
              )}
              {paymentType === PAYTYPE_REGULAR_CARD && (
                <>
                  <DonateFormItem>
                    <H4>카드번호</H4>
                    <Col>
                      <InputGroupCard>
                        <Controller
                          control={control}
                          name="cardNumber1"
                          rules={{
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          }}
                          as={
                            <InputNumber
                              format="####"
                              placeholder="1234"
                              onKeyUp={(e) =>
                                e.currentTarget.value.length === 4 &&
                                e.currentTarget.nextSibling?.nextSibling?.focus()
                              }
                              readOnly={isRegularDonor}
                            />
                          }
                        />
                        <InputGroupCardDash />
                        <Controller
                          control={control}
                          name="cardNumber2"
                          rules={{
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          }}
                          as={
                            <InputNumber
                              format="####"
                              placeholder="0000"
                              onKeyUp={(e) =>
                                e.currentTarget.value.length === 4 &&
                                e.currentTarget.nextSibling?.nextSibling?.focus()
                              }
                              readOnly={isRegularDonor}
                            />
                          }
                        />
                        <InputGroupCardDash />
                        <Controller
                          control={control}
                          name="cardNumber3"
                          rules={{
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          }}
                          as={
                            <InputNumber
                              format="####"
                              placeholder="0000"
                              onKeyUp={(e) =>
                                e.currentTarget.value.length === 4 &&
                                e.currentTarget.nextSibling?.nextSibling?.focus()
                              }
                              readOnly={isRegularDonor}
                            />
                          }
                        />
                        <InputGroupCardDash />
                        <Controller
                          control={control}
                          name="cardNumber4"
                          rules={{
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          }}
                          as={
                            <InputNumber
                              format="####"
                              placeholder="0000"
                              readOnly={isRegularDonor}
                            />
                          }
                        />
                      </InputGroupCard>
                    </Col>
                    {cardNumberError && (
                      <ErrorMessage>{cardNumberError.message}</ErrorMessage>
                    )}
                  </DonateFormItem>
                  <DonateFormItem>
                    <H4>유효기간</H4>
                    <InputGroupCard>
                      <Controller
                        control={control}
                        name="cardExpireM"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        }}
                        as={
                          <InputNumber
                            format="##"
                            placeholder="MM"
                            onKeyUp={(e) =>
                              e.currentTarget.value.length === 2 &&
                              e.currentTarget.nextSibling?.nextSibling?.focus()
                            }
                            readOnly={isRegularDonor}
                          />
                        }
                      />
                      <InputGroupCardSlash />
                      <Controller
                        control={control}
                        name="cardExpireY"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        }}
                        as={
                          <InputNumber
                            format="##"
                            placeholder="YY"
                            readOnly={isRegularDonor}
                          />
                        }
                      />
                    </InputGroupCard>
                    {cardExpireError && (
                      <ErrorMessage>{cardExpireError.message}</ErrorMessage>
                    )}
                  </DonateFormItem>
                  {/* 법인, 그외의 경우에 따라 분기 */}
                  {Object.keys(
                    MEMBER_TYPES[ENUM_SUPPORTER_TYPE.corporation],
                  ).includes(memberTypeCode || ``) ? (
                    // 법인
                    <DonateFormItem>
                      <H4>카드 소유주 사업자번호</H4>
                      <InputCheckbox
                        label="후원자 정보와 동일"
                        name="isCardBirthdaySame"
                        checked={isCardBirthdaySame}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValue(`cardOwnerBirthday`, businessNumber);
                          } else {
                            setValue(`cardOwnerBirthday`, ``);
                          }
                          setIsCardBirthdaySame(e.target.checked);
                        }}
                        readOnly={isRegularDonor}
                      />
                      <Controller
                        control={control}
                        name="cardOwnerBirthday"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                        }}
                        as={
                          <InputNumber
                            format="##########"
                            placeholder="사업자번호 입력(&lsquo;-&rsquo; 제외)"
                            readOnly={isRegularDonor || isCardBirthdaySame}
                            css={`
                              margin-top: 12px;
                            `}
                          />
                        }
                      />
                    </DonateFormItem>
                  ) : (
                    // 개인, 단체
                    <DonateFormItem>
                      <H4>카드 소유주 생년월일</H4>
                      <InputCheckbox
                        label="후원자 정보와 동일"
                        name="isCardBirthdaySame"
                        checked={isCardBirthdaySame}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValue(`cardOwnerBirthday`, birthday?.substring(2,8));
                          } else {
                            setValue(`cardOwnerBirthday`, ``);
                          }
                          setIsCardBirthdaySame(e.target.checked);
                        }}
                        readOnly={isRegularDonor}
                      />
                      <Controller
                        control={control}
                        name="cardOwnerBirthday"
                        rules={{
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                          validate: (value) =>
                            dayjs(value, `YYMMDD`, true).isValid() ||
                            `올바르지 않은 형식입니다`,
                        }}
                        as={
                          <InputNumber
                            format="######"
                            placeholder="생년월일 6자리 입력(YYMMDD)"
                            readOnly={isRegularDonor || isCardBirthdaySame}
                            css={`
                              margin-top: 12px;
                            `}
                          />
                        }
                      />
                      {errors.cardOwnerBirthday && (
                      <ErrorMessage>{errors.cardOwnerBirthday.message}</ErrorMessage>
                       )}
                    </DonateFormItem>
                  )}
                  <DonateFormItem noBorder>
                    <H4>결제일</H4>
                    <LabelFlex
                      css={`
                        margin: 0;
                      `}
                    >
                      <li>
                        <InputRadio
                          name="paymentDay"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          value="10"
                          label="10일"
                          readOnly={isRegularDonor}
                        />
                      </li>
                      <li>
                        <InputRadio
                          name="paymentDay"
                          ref={register}
                          value="20"
                          label="20일"
                          readOnly={isRegularDonor}
                        />
                      </li>
                      <li />
                    </LabelFlex>
                    {errors.paymentDay && (
                      <ErrorMessage>{errors.paymentDay.message}</ErrorMessage>
                    )}
                    <NotiBox
                      css={`
                        margin-top: 8px;
                      `}
                    >
                      <NotiTitle>신용카드 후원 시 유의사항</NotiTitle>
                      <NotiList>
                        <NotiItem>
                          신용카드의 경우, 카드사에 전달하는 과정을 거쳐야
                          합니다. 신청하신 약정일로부터 영업일(평일) 기준 2일
                          전에 신청하신 경우에만 해당 월에 후원이 가능합니다.
                        </NotiItem>
                        <NotiItem>
                          매월 선택하신 날짜에 약정하신 금액이 자동 인출 또는
                          승인됩니다.
                        </NotiItem>
                      </NotiList>
                    </NotiBox>
                  </DonateFormItem>
                </>
              )}
              {paymentType === PAYTYPE_REGULAR_NAVERPAY && (
                <>
                  <DonateFormItem>
                    <H4>결제일</H4>
                    <LabelFlex
                      css={`
                        margin: 0;
                      `}
                    >
                      <li>
                        <InputRadio
                          name="paymentDay"
                          ref={register({
                            required: {
                              value: true,
                              message: `필수 입력입니다`,
                            },
                          })}
                          value="12"
                          label="12일"
                          readOnly={isRegularDonor}
                        />
                      </li>
                      <li>
                        <InputRadio
                          name="paymentDay"
                          ref={register}
                          value="18"
                          label="18일"
                          readOnly={isRegularDonor}
                        />
                      </li>
                      <li />
                    </LabelFlex>
                    {errors.paymentDay && (
                      <ErrorMessage>{errors.paymentDay.message}</ErrorMessage>
                    )}
                    <NotiBox
                      css={`
                        margin-top: 8px;
                      `}
                    >
                      <NotiTitle>네이버페이 후원 시 유의사항</NotiTitle>
                      <NotiList>
                        <NotiItem>
                          네이버페이의 경우, 네이버페이에 전달하는 과정을 거쳐야
                          합니다. 신청하신 약정일로부터 영업일(평일) 기준 1일
                          전에 신청하신 경우에만 해당 월에 후원이 가능합니다.
                        </NotiItem>
                        <NotiItem>
                          매월 선택하신 날짜에 약정하신 금액이 자동 출금 또는
                          승인됩니다. 그러나 해당 일에 후원금이 전달되지 않은
                          경우 또는 미승인 시, 1회에 한해 선택하신 결제일 기준
                          이틀 뒤(12일→14일, 18일→20일)에 재출금이 진행됩니다.
                        </NotiItem>
                      </NotiList>
                    </NotiBox>
                  </DonateFormItem>
                </>
              )}
            </>
          )}
          {/* 후원모듈 후원직전알람 문구 출력 */}
          {moduleData?.moduleInfo && moduleData?.moduleInfo.optSupportAlert && (
            <FormTip type="blue" className="support-textbox">
              {moduleData?.moduleInfo.optSupportAlert}
            </FormTip>
          )}
          <AgreeHead>
            <H2>약관 및 개인정보 수집·이용 동의</H2>
          </AgreeHead>
          <Agreement
            allChk
            name="policyAgree"
            ref={register({
              required: {
                value: true,
                message: `이용약관에 동의해주세요`,
              },
            })}
            policies={[
              {
                required: true,
                label: `후원 약관 동의`,
                content: SupportAgreeContent,
              },
              {
                required: true,
                label: `개인정보 수집·이용 동의`,
                content: SupportPrivacyAgreeContent,
              },
            ]}
          />
          {errors.policyAgree && (
            <ErrorMessage>{errors.policyAgree.message}</ErrorMessage>
          )}
          <Button
            color="yellow"
            full
            css={`
              margin: 92px 0;
            `}
            type="submit"
            disabled={isSubmitting}
          >
            {supportTerm === SUPPORT_TERM_REGULAR && `매월 `}
            {finalAmount.toLocaleString()}원 후원하기
          </Button>
        </form>
      </FormContainer>
    );
  },
);

export default DonateStep3;
